<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-account-hard-hat-outline
        </v-icon> Create Work Order
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1">
            <v-row>
              <v-col
                cols="10"
              >
                <v-text-field
                  v-model="jobNumber"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Job Number*"
                  persistent-hint
                  hint="Add your job number."
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="description"
                  filled
                  label="Descripion*"
                  hint="Add your job description."
                  persistent-hint
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                v-if="isOrgInvoiceEnabled"
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="quoteAmount"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Quote Amount*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="status"
                  menu-props="auto"
                  :items="statusList"
                  label="status*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-checkbox
                  v-model="highRiskWork"
                  label="High Risk Work"
                  class="hrw-checkbox"
                />
              </v-col>
            </v-row>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="createWorkOrder()"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import ModuleHelper from 'src/helpers/module-helper';

  export default {
    name: 'CreateWorkOrder',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        loading: false,
        showModal: true,
        jobNumber: '',
        status: '',
        statusList: [{ id: 'pending', name: 'Pending' }, { id: 'completed', name: 'Completed' }],
        description: '',
        Amount: '',
        highRiskWork: false,
        isFormValid: false,
        quoteAmount: null,
        helper: new ModuleHelper(),
      };
    },
    computed: {
      isOrgInvoiceEnabled () {
        return (this.helper.isSubModuleExist(Constants.MODULE_WORK_ORDERS, Constants.SUB_MODULE_INVOICE));
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      async createWorkOrder () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('workorders/createWorkOrder', {
          workDetails: {
           job_number: this.jobNumber,
           status: this.status,
           description: this.description,
           high_risk: this.highRiskWork,
           quote_amount: this.quoteAmount,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: ' Work order created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
.v-input--selection-controls {
  margin-top: 0px;
}
.hrw-checkbox {
  margin-top: -30px;
}
</style>
