<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-hard-hat-outline
      </v-icon> Work Orders
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          color="#FF3700"
          @click="showForm = true"
        >
          Create New
        </v-btn>
      </v-col>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="workOrders"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.job_number }}</td>
            <td>{{ item.description }}</td>
            <td>
              <font
                :color="getHrwColor(item.high_risk)"
                class="font-my-style"
              >
                {{ item.high_risk | displayHighRisk }}
              </font>
            </td>
            <td>
              {{ item.created_at | convertToLocal }}
            </td>
            <td v-if="isOrgInvoiceEnabled">
              <font v-if="item.quote_amount !== null">
                <v-chip
                  outlined
                  class="ml-2"
                  color="#37474F"
                  dark
                >
                  {{ item.quote_amount }}
                </v-chip>
              </font><font v-else>
                N/A
              </font>
            </td>
            <td>
              <font
                :color="getColor(item.status)"
                class="font-my-style"
              >
                {{ item.status | statusUpdate }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                color="#37474F"
                x-small
                icon
                @click="editWorkOrderStatus(item.id, item.status)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="workOrderCheckIns(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <create-work-orders
      v-if="showForm"
      @closed="showForm = false"
      @success="getWorkOrders()"
    />
    <edit-work-order-status
      v-if="showEditStatusForm"
      :status="currentWorkOrderStatus"
      :work-order-id="workOrderId"
      @success="getWorkOrders"
      @closed="showEditStatusForm = false"
    />
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import createWorkOrders from 'src/views/dashboard/workorders/CreateWorkOrder.vue';
  import moment from 'moment';
  import EditWorkOrderStatus from './EditWorkOrderStatus.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';

  export default {
    name: 'WorkOrders',
    components: {
      'centre-spinner': spinner,
      'create-work-orders': createWorkOrders,
      'edit-work-order-status': EditWorkOrderStatus,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      displayHighRisk (status) {
        if (status === true) {
          return 'Yes*';
        } else {
          return 'No';
        }
      },
      statusUpdate (status) {
        if (status === 'pending') {
          return 'pending';
        } else {
          return 'Completed*';
        }
      },
    },
    data () {
      return {
        search: '',
        showForm: false,
        loading: false,
        headers: [
          { text: 'Job Number', align: 'start', value: 'job_number' },
          { text: 'Description', value: 'description' },
          { text: 'High Risk Work', value: 'high_risk' },
          { text: 'Created At', value: 'created_at' },
          { text: 'Status', value: 'status' },
          { text: 'Edit Status', value: 'edit' },
          { text: 'Check-Ins', value: 'check_ins' },
        ],
        showEditStatusForm: false,
        workOrderId: '',
        currentWorkOrderStatus: '',
        helper: new ModuleHelper(),
      };
    },
    computed: {
      workOrders () {
        return this.$store.getters['workorders/getWorkOrders'];
      },
      isOrgInvoiceEnabled () {
        return (this.helper.isSubModuleExist(Constants.MODULE_WORK_ORDERS, Constants.SUB_MODULE_INVOICE));
      },
    },
    watch: {
      isOrgInvoiceEnabled (val) {
        this.showQuoteAmount();
      },
    },
    created () {
      this.showQuoteAmount();
    },
    async mounted () {
      await this.getWorkOrders();
    },
    methods: {
      async getWorkOrders () {
        this.loading = true;
        await this.$store.dispatch('workorders/fetchWorkOrders');
        this.loading = false;
      },
      showQuoteAmount () {
        if (this.isOrgInvoiceEnabled === true && this.headers.some(item => item.text !== 'Quote Amount')) {
          this.headers.splice(4, 0, { text: 'Quote Amount', value: 'quote_amount' });
        }
      },
      getColor (status) {
        if (status === 'completed') return '#2E7D32';
        else { return '#C62828'; }
      },
      getHrwColor (value) {
        if (value === true) return '#2E7D32';
        else { return '#C62828'; }
      },
      editWorkOrderStatus (id, status) {
        this.currentWorkOrderStatus = status;
        this.workOrderId = id;
        this.showEditStatusForm = true;
      },
      workOrderCheckIns (workOrderId) {
        this.$router.push(`/work-orders/${workOrderId}`);
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.font-my-style {
  font-weight: bold;
  text-transform: capitalize;
}
</style>
